import { Indicators, Controllers } from './components'

import styles from './SliderController.module.scss'

export const SliderController = ({
  length,
  section,
  variant,
  visibleElementIndex,
  isAlwaysEnabled,
  customClass,
  onClick
}) => {
  const controllersData = {
    left: {
      id: `${section}-carouselPrevious-button`,
      disabled: !isAlwaysEnabled && visibleElementIndex === 0,
      onClick: () => onClick('left')
    },
    right: {
      id: `${section}-carouselNext-button`,
      disabled: !isAlwaysEnabled && visibleElementIndex === length - 1,
      onClick: () => onClick('right')
    }
  }

  return (
    <div className={`${styles.sliderController} ${customClass}`}>
      <Indicators
        length={length}
        variant={variant}
        visibleElementIndex={visibleElementIndex}
      />

      <Controllers variant={variant} data={controllersData} />
    </div>
  )
}
