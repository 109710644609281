import classnames from 'classnames'

import styles from './ProgressBar.module.scss'

export const ProgressBar = ({ classname, currentProgress, totalSteps }) => {
  return (
    <div className={`${styles.progressbar} ${classname}`}>
      <p className={styles.progressbar__numbers}>
        {currentProgress} de {totalSteps}
      </p>

      <div className={`${styles.progressbar__steps} ${classname}`}>
        {Array.from({ length: totalSteps }, (_, i) => (
          <div
            key={i}
            className={classnames(styles.progressbar__step, {
              [`${styles['progressbar__step--active']}`]: i < currentProgress
            })}
          />
        ))}
      </div>
    </div>
  )
}
