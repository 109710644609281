import { GA4_CLIENT_ID_LABEL } from '@/constants/api'
import isClient from '@equifax-ui/utils/browser/isClient'
import { newRelicNotifyError } from './trackingNewRelic'

export const getGa4ClientId = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = sessionStorage.getItem(GA4_CLIENT_ID_LABEL)

    if (!valueFromStorage) return undefined

    return JSON.parse(valueFromStorage)
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error getGa4ClientId: ${error}`)
    return undefined
  }
}

export const setGa4ClientId = async () => {
  if (!isClient()) return
  try {
    const valueFromStorage = sessionStorage.getItem(GA4_CLIENT_ID_LABEL)

    if (!valueFromStorage || JSON.parse(valueFromStorage) === '') {
      // eslint-disable-next-line no-undef
      window.gtag('get', ga4Id, 'client_id', (client_id) => {
        sessionStorage.setItem(GA4_CLIENT_ID_LABEL, JSON.stringify(client_id))
      })
    }
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error setGa4ClientId: ${error}`)
  }
}
