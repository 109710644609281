'use client'

import Image from 'next/image'

import classnames from 'classnames'

import { Container } from '@/components'

import { SocialMedia, Links } from './components'

import Efx from '/public/illustrations/efx.svg'
import Powering from '/public/illustrations/powering.svg'

import { LINKS } from './constants'

import styles from './Footer.module.scss'

export const Footer = ({ hasBannerSpacement }) => {
  return (
    <footer
      className={classnames(styles.footer, {
        [styles['footer--withBannerSpacement']]: hasBannerSpacement
      })}
    >
      <Container>
        <div className={styles.footer__links}>
          {LINKS.map(({ category, links }) => (
            <Links
              key={`${category}-links`}
              category={category}
              links={links}
            />
          ))}
        </div>

        <hr className={styles.footer__divider} />

        <div className={styles.footer__infos}>
          <div>
            <h4 className={styles.info__label}>Boa Vista Serviços S.A.</h4>
            <p className={styles.info__text}>CNPJ 11.725.176/0001-27</p>
            <p className={styles.info__text}>
              Av. Tamboré, 267 - 15º andar - Torre Sul, Barueri, SP - CEP
              06460-000
            </p>
            <p className={styles.info__disclaimer}>
              Não há atendimento presencial nesse endereço
            </p>
          </div>

          <div>
            <div className={styles.footer__infosGroup}>
              <div>
                <h4 className={styles.info__label}>Atendimento Empresas</h4>
                <p className={styles.info__text}>3003 0101</p>
              </div>
              <div>
                <h4 className={styles.info__label}>Central de Vendas</h4>
                <p className={styles.info__text}>0800 701 7887</p>
              </div>
              <div>
                <h4 className={styles.info__label}>Cadastro Positivo</h4>
                <p className={styles.info__text}>0800 727 0201</p>
              </div>
            </div>

            <SocialMedia />
          </div>
        </div>

        <hr className={styles.footer__divider} />

        <div className={styles.footer__address}>
          <picture>
            <Image src={Efx} alt="Equifax - BoaVista" />
          </picture>
          <p className={styles.footer__addressText}>
            Copyright 2024 Equifax Inc., Atlanta, Geórgia. Todos os direitos
            reservados.
          </p>
          <picture>
            <Image src={Powering} alt="Powering the world with knowledge" />
          </picture>
        </div>
      </Container>
    </footer>
  )
}
