import { Icon } from '@/components'

import styles from './HiringSteps.module.scss'

export const HiringSteps = ({ steps }) => {
  return (
    <ul className={styles.hiringSteps}>
      {steps.map(({ icon, description }, index) => (
        <li
          data-animate="fade-up"
          key={`card-${icon}-${index}`}
          className={`${styles.hiringSteps__container} animate-init`}
        >
          <p className={styles.hiringSteps__number}>{index + 1}</p>

          <div className={styles.hiringSteps__icon}>
            <Icon color="primary" name={icon} />
          </div>

          <p className={styles.hiringSteps__description}>{description}</p>
        </li>
      ))}
    </ul>
  )
}
