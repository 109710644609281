export const STEPS = {
  softlead: 'dados-basicos',
  'otp-verification': 'verificacao',
  plans: 'planos',
  business: 'dados-empresa',
  personal: 'dados-adicionais',
  summary: 'conclusao',
  client: 'cliente',
  default: 'dados-recebidos',
  'not-enriched': 'dados-recebidos',
  unavailable: 'indisponivel'
}

export const FEEDBACK_STEPS = [
  'client',
  'default',
  'not-enriched',
  'unavailable',
  'summary'
]
