import classnames from 'classnames'

import styles from './Subtitle.module.scss'

export const Subtitle = ({ text, variant, customClass }) => {
  return (
    <h4
      data-animate="fade-up"
      className={classnames(styles.subtitle, 'animate-init', {
        [customClass]: customClass,
        [styles[`subtitle--${variant}`]]: variant
      })}
    >
      {text}
    </h4>
  )
}
