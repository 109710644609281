import { Fragment } from 'react'

import { Divider } from '@/components'

import styles from './NavigationLinks.module.scss'

export const NavigationLinks = ({
  navigationLinks,
  headerNavigationLinks,
  closeMenu
}) => {
  return (
    <div>
      <div className={styles['navigation__links--mobile']}>
        {headerNavigationLinks.map(({ id, category, links }, index) => (
          <Fragment key={`header-${id}-category--${index}`}>
            <p className={styles.navigation__category}>{category}</p>

            {links.map(({ label, path, target, rel }, index) => (
              <a
                rel={rel}
                href={path}
                target={target}
                key={`header-${path}-link--${index}`}
                className={styles.navigation__link}
                onClick={closeMenu}
              >
                {label}
              </a>
            ))}

            <Divider />
          </Fragment>
        ))}
      </div>

      <div className={styles['navigation__links--desktop']}>
        {navigationLinks.map(({ label, path, target, rel }, index) => (
          <a
            rel={rel}
            href={path}
            target={target}
            className={styles.navigation__link}
            key={`header-${path}-link--${index}`}
            onClick={closeMenu}
          >
            {label}
          </a>
        ))}
      </div>
    </div>
  )
}
