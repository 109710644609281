export const REDIRECT_TO_LOGIN =
  'https://transacional.bvsnet.com.br/login.php?utm_source=lp_rv&utm_medium=fx_aquisicao'

export const CLIENT_PORTAL_URL = 'https://transacional.bvsnet.com.br/login.php'

const UTM_CAMPAIGN = NEXT_PUBLIC_BUILD_TYPE ?? 'rv' // eslint-disable-line no-undef

export const POSITIVE_CONSUMER_URL = `https://www.consumidorpositivo.com.br/?utm_source=cross-sell&utm_campaign=${UTM_CAMPAIGN}&utm_medium=equifax`

export const PRIVACY_POLITIC =
  'https://www.boavistaservicos.com.br/termo-de-uso-e-politica-de-privacidade/'
