export const LINKS = [
  {
    category: 'Equifax | BoaVista',
    links: [
      {
        id: 'about-link',
        url: 'https://www.boavistaservicos.com.br/sobre-a-boa-vista-scpc/',
        text: 'Sobre a Equifax | BoaVista'
      },
      {
        id: 'terms-privacy-link',
        url: 'https://www.boavistaservicos.com.br/termo-de-uso-e-politica-de-privacidade/',
        text: 'Termos de Uso e Politica de Privacidade'
      },
      {
        id: 'right-link',
        url: 'https://www.boavistaservicos.com.br/direito-de-arrependimento/',
        text: 'Direito de Arrependimento'
      },
      {
        id: 'blog-link',
        url: 'https://www.boavistaservicos.com.br/blog/',
        text: 'Blog Equifax | BoaVista'
      }
    ]
  },
  {
    category: 'Grupo Equifax | BoaVista',
    links: [
      {
        id: 'konduto-link',
        url: 'https://www.konduto.com/',
        text: 'Konduto'
      }
    ]
  },
  {
    category: 'Institucional',
    links: [
      {
        id: 'press-link',
        url: 'https://www.boavistaservicos.com.br/sala-de-imprensa/',
        text: 'Sala de imprensa'
      },
      {
        id: 'representative-link',
        url: 'https://materiais.boavistaservicos.com.br/representanteautorizado',
        text: 'Seja um representante'
      },
      {
        id: 'positive-font-link',
        url: 'https://www.boavistaservicos.com.br/fontes',
        text: 'Seja uma fonte positiva'
      }
    ]
  },
  {
    category: 'Informe-se',
    links: [
      {
        id: 'lgpd-link',
        url: 'https://www.boavistaservicos.com.br/protecao-de-dados/',
        text: 'LGPD'
      },
      {
        id: 'customer-service-link',
        url: 'https://www.boavistaservicos.com.br/ouvidoria/',
        text: 'Ouvidoria'
      },
      {
        id: 'payment-link',
        url: 'https://web2.bvsnet.com.br/transacional/login.php?tp_acesso=SUAFATURA',
        text: '2ª via de boleto'
      }
    ]
  },
  {
    category: 'Para você',
    links: [
      {
        id: 'cpf-consultation-link',
        url: 'https://consumidor.boavistaservicos.com.br/consulta-cpf-gratis/',
        text: 'Consulta de CPF'
      },
      {
        id: 'positive-registration-link',
        url: 'https://consumidor.boavistaservicos.com.br/cadastro-positivo/',
        text: 'Cadastro Positivo'
      },
      {
        id: 'contact-link',
        url: 'https://consumidor.boavistaservicos.com.br/fale-conosco/',
        text: 'Fale conosco'
      }
    ]
  }
]

export const CONTACT_NUMBERS = [
  {
    label: 'Atendimento Empresas',
    phone_number: '3003 0101'
  },
  {
    label: 'Central de Vendas',
    phone_number: '0800 701 7887'
  },
  {
    label: 'Cadastro Positivo',
    phone_number: '0800 727 0201'
  }
]
