'use client'

import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useExperiment } from 'rvbr-www/red-testing'

import { useProposalContext } from '@/context/ProposalContext'

export const usePaywall = () => {
  const {
    control,
    watch,
    formState: { isValid }
  } = useForm({
    mode: 'onChange'
  })

  const { activateExperiment } = useExperiment('paywall')
  const { setIsPaywallOpen } = useProposalContext()

  const customTracking = {
    name: 'softlead',
    payload: { cnpj: watch('cnpj') }
  }

  useEffect(() => {
    activateExperiment()
  }, [])

  const onCloseModal = () => setIsPaywallOpen(false)

  return {
    control,
    isValid,
    customTracking,
    onCloseModal
  }
}
