import isClient from '@equifax-ui/utils/browser/isClient'

import { AFFILIATE_LABEL } from '@/constants'
import {
  AFFILIATE_PLAN,
  CUSTOM_PLAN,
  POST_PAID_PLANS,
  PRE_PAID_PLANS
} from '@/constants/plans'

export const usePlans = () => {
  const isAffiliate =
    isClient() && JSON.parse(sessionStorage.getItem(AFFILIATE_LABEL))

  const planID = isClient() && sessionStorage.getItem('bvs-plan')

  const customPlan = isAffiliate ? AFFILIATE_PLAN : CUSTOM_PLAN
  const postpaidPlans = [...POST_PAID_PLANS, customPlan]

  const planInStorage = postpaidPlans.find(({ id }) => id === planID)

  return {
    planInStorage,
    postpaidPlans,
    prepaidPlans: PRE_PAID_PLANS
  }
}
