import { REFERRER_LABEL } from '@/constants/api'
import isClient from '@equifax-ui/utils/browser/isClient'
import { newRelicNotifyError } from './trackingNewRelic'

export const getDocumentReferrer = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = sessionStorage.getItem(REFERRER_LABEL)

    if (!valueFromStorage) return undefined

    return JSON.parse(valueFromStorage)
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error getDocumentReferrer: ${error}`)
    return undefined
  }
}

export const setDocumentReferrer = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = sessionStorage.getItem(REFERRER_LABEL)

    if (!valueFromStorage || JSON.parse(valueFromStorage) === '') {
      sessionStorage.setItem(REFERRER_LABEL, JSON.stringify(document.referrer))
    }
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error setDocumentReferrer: ${error}`)
  }
}
