'use client'

import { useState, useEffect } from 'react'

import Image from 'next/image'

import isClient from '@equifax-ui/utils/browser/isClient'

import { useWhatsapp } from '@/hooks/useWhatsapp'

import WhatsappImage from '/public/icons/whatsapp-image.svg'

import { Button, If, Loader } from '..'

import { AFFILIATE_LABEL } from '@/constants'

export const ButtonWhatsapp = ({
  id,
  customClass,
  variant,
  children,
  disabled,
  customTracking
}) => {
  const [shouldRenderWhatsapp, setShouldRenderWhatsapp] = useState(false)

  const { isLoading, onClickWhatsappButton } = useWhatsapp({
    customTracking
  })

  useEffect(() => {
    const isAffiliate =
      isClient() && JSON.parse(sessionStorage.getItem(AFFILIATE_LABEL))

    setShouldRenderWhatsapp(!isAffiliate)
  }, [])

  return (
    <If condition={shouldRenderWhatsapp}>
      <Button
        id={id}
        variant={variant}
        disabled={disabled}
        className={customClass}
        onClick={onClickWhatsappButton}
      >
        <If
          condition={isLoading}
          renderIf={<Loader />}
          renderElse={
            <>
              <Image
                priority
                width={24}
                height={24}
                src={WhatsappImage}
                alt="Whatsapp logo"
              />
              {children}
            </>
          }
        />
      </Button>
    </If>
  )
}
