import { SOURCE_URL_LABEL } from '@/constants/api'
import isClient from '@equifax-ui/utils/browser/isClient'
import { newRelicNotifyError } from './trackingNewRelic'

export const getSourceUrl = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = sessionStorage.getItem(SOURCE_URL_LABEL)

    if (!valueFromStorage) return undefined

    return JSON.parse(valueFromStorage)
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error getSourceUrl: ${error}`)
    return undefined
  }
}

export const setSourceUrl = async () => {
  if (!isClient()) return
  try {
    const valueFromStorage = sessionStorage.getItem(SOURCE_URL_LABEL)

    if (!valueFromStorage || JSON.parse(valueFromStorage) === '') {
      sessionStorage.setItem(
        SOURCE_URL_LABEL,
        JSON.stringify(window.location.href)
      )

      window?.dataLayer.push({ sourceUrl: window.location.href })
    }
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error setSourceUrl: ${error}`)
  }
}
