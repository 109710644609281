import { useState } from 'react'

export const useProposal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onCatch = () => {
    setHasError(true)
    setIsLoading(false)
  }

  return {
    isLoading,
    hasError,
    setHasError,
    onCatch
  }
}
