import classnames from 'classnames'

import { Badge, ButtonWhatsapp, Divider, Icon, If } from '..'
import Radio from '../Form/Vanilla/Radio'

import { Action, Details } from './components'

import { usePlan } from './usePlan'

import styles from './Plan.module.scss'

const defaultAction = {
  id: 'plan-seeDetails-button',
  text: 'Ver detalhes',
  leftIcon: 'equifax-finance-chip',
  rightIcon: 'equifax-arrow-down'
}

export const Plan = ({
  plan = {},
  icon,
  children,
  customClass,
  expandedPlanID,
  selectedPlan,
  action = defaultAction,
  isActive,
  setExpandedPlanID,
  onClick
}) => {
  const { isNotCustom, onClickAction, onSelectPlan } = usePlan({
    plan,
    action,
    expandedPlanID,
    setExpandedPlanID,
    onClick
  })

  return (
    <div
      id={`plan-${plan.id}-card`}
      onClick={onSelectPlan}
      className={classnames(styles.plan, {
        ['--gtm-click']: isNotCustom && onClick,
        [styles['plan--clickable']]: isNotCustom && onClick,
        [styles['plan--active']]: isActive || selectedPlan === plan.id,
        [customClass]: customClass
      })}
    >
      <div className={styles.plan__heading}>
        <div>
          <If condition={icon}>
            <Icon
              name={icon}
              size="small"
              color="white"
              className={styles.plan__icon}
            />
          </If>
          <label htmlFor="plan">{plan.name}</label>
        </div>

        <If condition={plan.is_best_selling}>
          <Badge round color="red" icon="equifax-stars" text="Mais vendido" />
        </If>

        <If condition={onClick && isNotCustom}>
          <Radio
            name="plan"
            value={plan.id}
            checked={selectedPlan === plan.id}
            onChange={(_, value) => onClick(value)}
          />
        </If>
      </div>

      <If condition={plan.discount}>
        <p className={styles.plan__discount}>
          Economize {plan.discount} por consulta
        </p>
      </If>

      <div
        className={`${styles.plan__content} ${styles[`plan__content--${plan.id}`]} ${!plan.discount && styles['plan__content--emptyDiscount']}`}
      >
        <div>
          <p className={styles.plan__highlightText}>{plan.highlight_text}</p>

          <If condition={isNotCustom}>
            <p className={styles.plan__text}>
              Média de <b>{plan.consultations_per_month} consultas</b> por mês
            </p>

            <Divider />
          </If>

          <p className={styles.plan__text}>{plan.text}</p>

          <If condition={isNotCustom}>
            <Action
              {...action}
              id={`${action.id}--${plan.id}`}
              isExpanded={expandedPlanID === plan.id}
              onClick={onClickAction}
            />
          </If>

          <Details
            cpfDetails={plan.cpf_details}
            cnpjDetails={plan.cnpj_details}
            isExpanded={expandedPlanID === plan.id}
          />
        </div>

        {children}

        <If condition={plan.id === 'custom'}>
          <ButtonWhatsapp iconColor="white" id="plans-whatsapp-button">
            Quero contratar
          </ButtonWhatsapp>
        </If>
      </div>
    </div>
  )
}
