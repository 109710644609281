import { Icon } from '..'

import styles from './NewModal.module.scss'

export const NewModal = ({
  children,
  customClass,
  section,
  title,
  onClose
}) => {
  return (
    <div className={styles.modal} data-overlay="true">
      <div className={`${styles.modal__content} ${customClass}`}>
        <div className={styles.modal__header}>
          <p className={styles.modal__title}>{title}</p>

          <button id={`${section}-close-button`} onClick={onClose}>
            <Icon name="equifax-close" size="small" />
          </button>
        </div>

        {children}
      </div>
    </div>
  )
}
