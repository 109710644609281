const dataLayerTrack = (
  event,
  args,
  { renewSession = false, includeContext = true } = {}
) => {
  window.dataLayer.push(
    {
      event,
      ...args
    },
    renewSession,
    includeContext
  )

  console.log('%c @analytics/tracr - track', 'color: #2f429b', {
    event,
    ...args
  })
}

const trackEvents = {
  formInteracted: ({ cnpj = '', name = '', email = '', phone = '' }) => {
    const payload = {
      cnpj,
      name,
      phone,
      mail: email
    }

    dataLayerTrack('equifax.FormInteracted', payload)
  },
  nps: (payload) => {
    dataLayerTrack('equifax.NPS', { ...payload, grade: Number(payload.grade) })
  },
  softlead: (payload) => {
    dataLayerTrack('equifax.Softlead', payload)
  }
}

export const triggerTracrTrack = (name, data) => {
  if (name in trackEvents) {
    const event = trackEvents[name]
    return event(data)
  } else {
    const error = new Error(`[@analytics] ${name} does not exit in events`)

    return Promise.reject(error)
  }
}
