import { Controller } from 'react-hook-form'

import Input from '@/components/Form/Vanilla/Input'

import useInput from '../Text/hooks'

const CnpjField = ({
  name,
  label,
  control,
  className,
  defaultValue,
  rules = null,
  type = 'text',
  disabled = false,
  onChange = (e) => e,
  onBlur = (e) => e,
  ...props
}) => {
  const { inputRef } = useInput()
  const validRules = rules ? { rules } : {}
  return (
    <Controller
      {...validRules}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange: formChange, onBlur: formBlur, ...field },
        fieldState: { error }
      }) => (
        <Input.Default
          mask="99.999.999/9999-99"
          label={label}
          className={className}
          type={type}
          disabled={disabled}
          message={error?.message}
          onChange={(name, value, event) => {
            inputRef.current.value = value
            formChange(event)
            onChange(name, value, event)
          }}
          onBlur={(name, value, event) => {
            inputRef.current.value = value
            formBlur(event)
            onBlur(name, value, event)
          }}
          {...field}
          {...props}
        />
      )}
    />
  )
}

export default CnpjField
