'use client'

import { useState } from 'react'

import { usePlans } from '@/hooks/usePlans'

import { Plan } from '@/components'

import styles from './PlanList.module.scss'

export const PlanList = ({ selectedPlan, setSelectedPlan }) => {
  const [expandedPlanID, setExpandedPlanID] = useState()

  const { postpaidPlans } = usePlans()

  return (
    <div className={styles.plans}>
      {postpaidPlans.map((plan) => (
        <Plan
          plan={plan}
          key={`plans-${plan.id}`}
          selectedPlan={selectedPlan || 'advanced'}
          customClass={plan.id === 'custom' && styles.plans__customPlan}
          expandedPlanID={expandedPlanID}
          setExpandedPlanID={setExpandedPlanID}
          onClick={setSelectedPlan}
        />
      ))}
    </div>
  )
}
