import classNames from 'classnames'

import styles from './Details.module.scss'

export const Details = ({ cpfDetails, cnpjDetails, isExpanded }) => {
  return (
    <div
      className={classNames(styles.details, {
        [styles['details--expanded']]: isExpanded
      })}
    >
      {cpfDetails?.map(({ name, value }, i) => (
        <div
          key={`cpfInfo-${i}`}
          className={`${styles.details__item} ${i === 0 && styles['details__item--bold']}`}
        >
          <span>{name}</span>
          <span>{value}</span>
        </div>
      ))}

      <hr className={styles.details__divisor} />

      {cnpjDetails?.map(({ name, value }, i) => (
        <div
          key={`cnpjInfo-${i}`}
          className={`${styles.details__item} ${i === 0 && styles['details__item--bold']}`}
        >
          <span>{name}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  )
}
