'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'

import { useExperiment } from 'rvbr-www/red-testing'

import { useProposalContext } from '@/context/ProposalContext'

import { get } from '@/services/general'

import { triggerTracrTrack } from '@/hooks/useAnalytics'

import { newRelicNotifyError } from '@/utils/trackingNewRelic'

import {
  WHATSAPP_BASE_URL,
  WHATSAPP_PHONE_NUMBERS,
  WHATSAPP_URL_PARAMS
} from '@/constants/whatsapp'

export const useWhatsapp = ({ customTracking } = {}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { isPaywallOpen, setIsPaywallOpen } = useProposalContext()
  const { variant, successfulExperiment } = useExperiment('paywall')

  const pathName = usePathname()
  const isFormRoute = pathName.includes('formulario')
  const isPaywallExperiment = variant.id === 'b' && !isFormRoute

  const getWhatsappNumber = async () => {
    try {
      const {
        data: { phone_number }
      } = await get({
        path: `/chat-status?origin=${origin}`,
        config: {
          timeout: 3000,
          headers: { 'app-key': APP_KEY_WPP_REDIRECT } // eslint-disable-line no-undef
        }
      })

      return phone_number
    } catch (error) {
      newRelicNotifyError('Getting whatsapp redirect url')
    }
  }

  const mountWhatsappURL = (phoneNumber) => {
    if (phoneNumber) {
      return `${WHATSAPP_BASE_URL}${phoneNumber}${WHATSAPP_URL_PARAMS}`
    }

    const origin = NEXT_PUBLIC_BUILD_TYPE ?? 'paid' // eslint-disable-line no-undef
    return `${WHATSAPP_BASE_URL}${WHATSAPP_PHONE_NUMBERS[origin]}${WHATSAPP_URL_PARAMS}`
  }

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappURL = mountWhatsappURL(phoneNumber)

    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    if (isMobileDevice) {
      window.location.href = whatsappURL
      return
    }

    window?.open(whatsappURL, '_blank')
  }

  const goToWhatsapp = async () => {
    if (isFormRoute) {
      redirectToWhatsApp()
      return
    }

    setIsLoading(true)

    const phoneNumber = await getWhatsappNumber()

    setIsLoading(false)
    redirectToWhatsApp(phoneNumber)
  }

  const onClickWhatsappButton = () => {
    if (isPaywallExperiment) {
      if (!isPaywallOpen) {
        setIsPaywallOpen(true)
        return
      }

      successfulExperiment()
      triggerTracrTrack(customTracking.name, customTracking.payload)
    }

    goToWhatsapp()
  }

  return {
    isLoading,
    goToWhatsapp,
    onClickWhatsappButton
  }
}
