export const usePlan = ({
  plan,
  action,
  expandedPlanID,
  setExpandedPlanID,
  onClick
}) => {
  const isNotCustom = plan.id !== 'custom'

  const onClickAction = () => {
    if (action?.onClick) {
      action.onClick()
      return
    }

    onExpandDetails()
  }

  const onExpandDetails = () => {
    if (expandedPlanID === plan.id) {
      setExpandedPlanID(null)
      return
    }

    setExpandedPlanID(plan.id)
  }

  const onSelectPlan = () => {
    if (plan.id !== 'custom') {
      onClick && onClick(plan.id)
    }
  }

  return {
    isNotCustom,
    onClickAction,
    onSelectPlan
  }
}
