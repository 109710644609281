import styles from './Tag.module.scss'

export const Tag = ({ bgColor, children }) => (
  <div
    data-animate="fade-up"
    className={`${styles.tag} ${styles[`tag--${bgColor}`]} animate-init`}
  >
    <label>{children}</label>
  </div>
)
