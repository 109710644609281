/* eslint-disable no-undef */

import { TOKEN_LABEL } from '@/constants'

const axios = require('axios')

const axiosInstance = axios.create({
  baseURL: `${api.default.baseUrl}/${api.default.version}`
})

const axiosExternalInstance = axios.create({})

const isTokenDateValid = (tokenExpiration) => {
  if (!tokenExpiration) return false

  const tokenExpirationDate = new Date(tokenExpiration * 1000)
  const dateNow = new Date()

  return dateNow <= tokenExpirationDate
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const tokenFromStorage = sessionStorage.getItem(TOKEN_LABEL)
    const authToken = JSON.parse(tokenFromStorage || '{}')

    const recaptcha = await grecaptcha
      .execute(reCaptchaId)
      .then((token) => token)

    config.headers['Content-Type'] = 'application/json'
    config.headers['X-Recaptcha-Token'] = recaptcha

    if (!tokenFromStorage || !isTokenDateValid(authToken.expires_at)) {
      return config
    }

    config.headers['Authorization'] = `Bearer ${authToken.token}`

    return config
  },
  (error) => {
    return Promise.reject(error) // eslint-disable-line
  }
)

export { axiosInstance, axiosExternalInstance }
