'use client'

import { useEffect } from 'react'

import classnames from 'classnames'

import { customPageView } from '@/utils/trackingGTM'

import { useProposalContext } from '@/context/ProposalContext'

import { Button, SkeletonLoader, Toast } from '..'

import styles from './Step.module.scss'

export const Step = ({
  title,
  subtitle,
  children,
  customClass,
  topContent,
  actions = []
}) => {
  const { toastData, isLoading } = useProposalContext()

  const stepClasses = classnames(styles.step, { [customClass]: customClass })

  useEffect(() => {
    customPageView()
  }, [])

  return (
    <>
      {isLoading ? (
        <SkeletonLoader.Step />
      ) : (
        <>
          {topContent}

          <Toast {...toastData} />

          <div className={stepClasses}>
            <header className={styles.step__header}>
              <h1 className={styles.step__title}>{title}</h1>
              <h2 className={styles.step__subtitle}>{subtitle}</h2>
            </header>
            {children}
          </div>

          <div className={styles.controllers}>
            {actions.map(({ id, variant, text, disabled = false, onClick }) => (
              <Button
                id={id}
                key={id}
                variant={variant}
                disabled={disabled}
                onClick={onClick}
              >
                {text}
              </Button>
            ))}
          </div>
        </>
      )}
    </>
  )
}
