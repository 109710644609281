import { useForm } from 'react-hook-form'

import { triggerTracrTrack } from '@/hooks/useAnalytics'

export const useGrading = ({ setIsNPSSent }) => {
  const {
    formState: { isValid },
    handleSubmit,
    register
  } = useForm({
    mode: 'onChange'
  })

  const onSubmit = (data) => {
    triggerTracrTrack('nps', data)
    setIsNPSSent(true)
  }

  return {
    isValid,
    register,
    handleSubmit,
    onSubmit
  }
}
